import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_2_week from '../../../../../../../common/src/assets/image/example_2_week.png';
import template_options_2_weeks from '../../../../../../../common/src/assets/image/template_options_2_weeks.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        The 2-Weeks/Page template generates 2 weeks to a single page, split down
        the middle with one week on the left and one on the right. The page can
        be cut in half and placed in a binder.
      </p>
      <p>
        <Image alt="" src={example_2_week} style={{ width: 759 }} />
      </p>
      <h2>Template Settings</h2>
      <p>
        The 2-Weeks/Page template provides the following options when creating a
        calendar...
      </p>
      <Image alt="" src={template_options_2_weeks} style={{ width: 448 }} />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Start date</strong>
            </td>
            <td>
              The start date can be typed into the box or selected using the
              drop-down arrow. Typically, the start date should correspond with
              the first day of the week, although any date can be used as the
              starting date..
            </td>
          </tr>
          <tr>
            <td>
              <strong>Number of weeks</strong>
            </td>
            <td>
              The number of weeks to generate, in increments of two. If more
              than 2 weeks are selected, additional weeks will be generated to
              separate worksheets or pages.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Text behavior</strong>
            </td>
            <td>
              Titles can be wrapped (if space allows), never wrapped, or shrunk
              to fit so that wrapping is unnecessary. You can pick the desired
              behavior via the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Item font size</strong>
            </td>
            <td>
              Override the default font size associated with each calendar data
              source.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Time Format</strong>
            </td>
            <td>
              The time format. Supported formats are <strong>12-hour</strong>{' '}
              (8:00 PM), <strong>24-hour</strong> (20:00),{' '}
              <strong>Shortened</strong> (8pm), <strong>Military </strong>
              (2000), and <strong>Numeric </strong>(20.0). An example time is
              shown for each format in the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Only use background colors for all-day items</strong>
            </td>
            <td>
              When checked, only events or tasks that are all-day or span
              multiple days will have a background color. Other items will have
              their text colored without a background.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show start time</strong>
            </td>
            <td>
              When checked, event or task start times will be shown. The
              ordering of times, title, and location values can be specified in
              the <Link to="/Documentation/Options">PrintableCal options</Link>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show end time</strong>
            </td>
            <td>
              <span>When checked, event or task end times will be shown. </span>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Use bold text for item times</strong>
            </td>
            <td>
              When checked, item titles will be in bold text, to help
              differentiate from the time and location values.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show location</strong>
            </td>
            <td>When checked, event or task locations will be shown.</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Week-2-Weeks-Page"
      commentsId="commentsplus_post_170_489"
      title="2-Weeks/Page Template"
      description="Generate 2 weeks to a single page, split down the middle with one week on the left and one on the right. The page can be cut in half and placed in a binder."
      keywords="two week template, 2 week template, split binder template, week grid template"
      content={content}
    />
  );
};

export default Documentation;
